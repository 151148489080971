import React from "react"
import Layout from "../components/layout"
import { useStoryblokState } from "gatsby-source-storyblok"
import Sidebar from "../components/Sidebar_Navigation"
import SourcePage from "../components/SourcePage"

export default function SourcePageIndex({ pageContext, location }) {
    const story = useStoryblokState(pageContext.story,location)
    const { content } = story
    const { breadcrumbs, crumbLabel } = content || {}

    return (
      <Layout
        className="layoutclass"
        location={location}
        full_slug={story?.full_slug}
        lang={story?.lang}
        breadcrumbs={breadcrumbs}
        crumb={crumbLabel}
        pathname={pageContext.pathname}

        meta={{ ...story?.content?.Meta, tracking_variable: story?.content?.tracking_variable }}
      >
        <main className="container">
          <div className="sidebar-section">
            <div className="sidebar-section-left">
              <Sidebar story={story} location={location}/>
            </div>
            <div className="main-content">
              <SourcePage story={story} location={location} />
            </div>
          </div>
        </main>
      </Layout>
    );
}